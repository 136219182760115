import Vue from 'vue'
import VueLogger from 'vuejs-logger'
import store from '@/store'
import EventBus from '@/utils/event-bus'
import lodash, { forEach } from 'lodash-es'
import moment from 'moment-timezone'
import 'moment/locale/fr'
import VueScrollTo from 'vue-scrollto'
import StarRating from 'vue-star-rating'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required,
  email,
  numeric,
  digits,
  min,
  regex,
  confirmed,
} from 'vee-validate/dist/rules'
import './icons' // icon
/*
 *
 */
import LoginComponent from '@/components/LoginComponent.vue'
import Navbar from '@/components/partials/Navbar/index.vue'
import UpdateRequest from '@/apps/userAccount/components/Trainings/UpdateRequest.vue'
import CheckMyEligibilityModal from '@/apps/single-training/components/modals/CheckMyEligibilityModal.vue'
import '@/styles/index.scss'
/*
 *
 */
const isProduction = process.env.NODE_ENV === 'production'
/*
 *
 */
window.dataLayer = window.dataLayer || []
/*
 *
 */
const vueLoggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
}
Vue.use(VueLogger, vueLoggerOptions)
Vue.use(VueScrollTo)
/*
 *
 */
Vue.component('Navbar', Navbar)
Vue.component('LoginComponent', LoginComponent)
Vue.component('UpdateRequest', UpdateRequest)
Vue.component('StarRating', StarRating)
Vue.component('CheckMyEligibilityModal', CheckMyEligibilityModal)

/*
 *
 */

extend('required', {
  ...required,
  message: 'Champ obligatoire ',
})
extend('email', {
  ...email,
  message: 'Adresse e-mail invalide',
})
extend('numeric', {
  ...numeric,
  message: 'le champ dois étre numérique',
})

extend('digits', {
  ...digits,
  message: '',
})
extend('min', {
  ...min,
  message: '',
})
extend('regex', {
  ...regex,
  message: '',
})
extend('confirmed', {
  ...confirmed,
  message: 'Les mots de passe ne correspondent pas',
})
/*
 *
 */

/*
 *
 */
Object.defineProperties(Vue.prototype, {
  $bus: {
    get() {
      return EventBus
    },
  },
})
Object.defineProperty(Vue.prototype, '$lodash', { value: lodash })
Object.defineProperty(Vue.prototype, '$moment', { value: moment })
/*
 *
 */
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

/*
 *
 */

Vue.config.productionTip = false

new Vue({
  el: '#app',
  store,
  created() {
    this.$store.commit('app/SET_API_BASEURL', process.env.VUE_APP_BASE_API)
    this.$store.dispatch('cart/getCartTotalItem')
  },

  mounted() {
    this.isAuthenticated = false
    this.$store.dispatch('app/getUserAccount').then((res) => {
      this.isAuthenticated = true

      
      const catalogFormationDownloadDesktop = document.getElementById(
        'catalog-formation-download-desktop'
      )
      const catalogFormationDownloadDesktopFooter = document.querySelector(
        '.catalog-formation-download-desktop a'
      )
      console.log(
        'catalogFormationDownloadDesktopFooter ',
        catalogFormationDownloadDesktopFooter
      )
      const catalogFormationDownloadMobile = document.getElementById(
        'catalog-formation-download-mobile'
      )
      const catalogFormationdMenuMobile = document.getElementsByClassName(
        'catalogue-menu-mobile'
      )
      const catalogFormationdMenu =
        document.getElementsByClassName('catalogue-menu')
      const catalogFormationdMenuEl = catalogFormationdMenu.length
        ? catalogFormationdMenu[0].firstElementChild
        : null
      const catalogFormationdMenuMobileEl = catalogFormationdMenuMobile.length
        ? catalogFormationdMenuMobile[0].firstElementChild
        : null
      if (lodash.isEmpty(res)) {
        if (catalogFormationDownloadDesktop !== null) {
          this.hideCatalogFormationMenu(catalogFormationDownloadDesktop, false)
        }
        if (catalogFormationDownloadDesktopFooter !== null) {
          this.hideCatalogFormationMenu(catalogFormationDownloadDesktopFooter, false)
        }

        if (catalogFormationDownloadMobile !== null) {
          this.hideCatalogFormationMenu(catalogFormationDownloadMobile, false)
        }
        if (catalogFormationdMenuEl !== null) {
          this.hideCatalogFormationMenu(catalogFormationdMenuEl, false)
        }
        if (catalogFormationdMenuMobileEl !== null) {
          this.hideCatalogFormationMenu(catalogFormationdMenuMobileEl, false)
        }
      } else {
        if (catalogFormationDownloadDesktop !== null) {
          this.hideCatalogFormationMenu(catalogFormationDownloadDesktop, true)
        }
        if (catalogFormationDownloadDesktopFooter !== null) {
          this.hideCatalogFormationMenu(catalogFormationDownloadDesktopFooter, true)
        }
        if (catalogFormationDownloadMobile !== null) {
          this.hideCatalogFormationMenu(catalogFormationDownloadMobile, true)
        }
        if (catalogFormationdMenuEl !== null) {
          this.hideCatalogFormationMenu(catalogFormationdMenuEl, true)
        }
        if (catalogFormationdMenuMobileEl !== null) {
          this.hideCatalogFormationMenu(catalogFormationdMenuMobileEl, true)
        }
      }
      this.$bus.$emit('user.ready')
    })
  },
  methods: {
    hideCatalogFormationMenu(menu, isAuthenticated) {
      console.log('menu ', menu);
      console.log('isAuthenticated ', isAuthenticated);
      if (!isAuthenticated) {
        menu.setAttribute('href', '#')
      } else {
        console.log('Connected ')
      }
      menu.addEventListener('click', (e) => {
        if (!isAuthenticated) {
          store.state.app.loginMessage =
            "Merci de vous connecter afin d'accéder à ce contenu"
          setTimeout(
            () => (this.$store.state.app.isCardLoginIsShowing = true),
            500
          )
          e.preventDefault()
        }
      })
    },
  },
})
